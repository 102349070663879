.App {
  display:flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  text-align: center;
  background-color: #282c34;
}

.App-logo {

  top:0px;
  left:0;
  height: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0s linear;
  }
}

.App-header {
  background-color: #111316;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(105, 177, 199);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.option {
  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 8px;
  border-width: 15px;
  border: 5em;
  border-color:burlywood;
}

.option-clicked { 
  background-color:rgba(83, 158, 123, 0.664);
  border-radius: 8px;
  border-width: 15;
  border: 5em;
  border-color:burlywood;
}

.option:hover {
  background-color:rgba(83, 158, 123, 0.664);
  cursor: pointer;
}

.nav {
  background-color:rgba(37, 37, 37, 0.589);
  height: 60px;
}

.nav:hover {
  background-color:rgba(122, 122, 122, 0.333);
  color:antiquewhite;
  cursor: pointer;
}

.info {

  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 8px;
  border-width: 15px;
  border: 5em;
  border-color:burlywood;
}

.infomiddle {

  background-color:rgba(83, 158, 123, 0.664);
  border-radius: 8px;
  border-width: 15px;
  border: 5em;
  border-color:burlywood;
}
.button {
  display: flex;
  width: 128px;
  height: 42px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin: 4px;
  font-size: 13px;
  font-weight:400;
  color:antiquewhite;
  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 10px;
  cursor: pointer;
}

.langbutton {
  padding: 0.5vh;
  padding-left: 5%;
  padding-right: 5%;
  margin: 1vh;
  font-size: 13px;
  font-weight:400;
  color:antiquewhite;
  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 10px;
  cursor: pointer;
}

.weiterbutton {
  display: flex;
  width: 128px;
  height: 42px;
  justify-content: center;
  align-items: center;
  margin-top:16px;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight:400;
  color:antiquewhite;
  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 10px;
  cursor: pointer;
}
.weiterbutton:hover {
  display: flex;
  width: 128px;
  height: 42px;
  justify-content: center;
  align-items: center;
  margin-top:16px;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight:400;
  color:antiquewhite;
  background-color:rgba(110, 245, 166, 0.333);
  border-radius: 10px;
  cursor: pointer;
}

.resetbutton {
  display: flex;
  width: 128px;
  height: 42px;
  justify-content: center;
  align-items: center;
  margin-top:16px;
margin-bottom: 16px;
  font-size: 13px;
  font-weight:400;
  color:antiquewhite;
  background-color:rgba(122, 122, 122, 0.333);
  border-radius: 10px;
  cursor: pointer;
}
